// Datei: home.js
import React from 'react';
import './Home.css';
import WaterWave from 'react-water-wave';
import image from '../images/backgrounds/firstComponentFrameContent.png';


const wallet= false;

function Home() {


  return (

   <WaterWave className="home-container"
    imageUrl= {image}
    dropRadius={20}
    perturbance={0.01}
    interactive={true}
    >
    {methods => (
      <div className="overlay">
        {/* Hier können Sie den restlichen Inhalt der Komponente einfügen */}
      </div>
      
      
    )}
    </WaterWave> 


    
   
  );
}

export default Home;





