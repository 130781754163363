
import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import Icon from '../images/icons/Icon_800_R0.png';

function Navbar() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const toggleBtn = document.querySelector('.toggle_btn');
    const toggleBtnIcon = document.querySelector('.toggle_btn i');
    const dropDownMenu = document.querySelector('.dropdown_menu');

    toggleBtn.onclick = function () {
      dropDownMenu.classList.toggle('open');
      setDropdownOpen(!isDropdownOpen);
    };
  }, [isDropdownOpen]);

  return (
    <header>
      <div className="navbar">
        <div className="logo">
         {/* <a><img src={Icon} alt="Logo" height="50px"/></a> */}
         <a>Tony & Clones</a>
        </div>
        <ul className="links">
          <li className="navbar-link-item">
            <a href="#" className="navbar-link">
              Home
            </a>
          </li>
          <li className="navbar-link-item">
            <a href="#" className="navbar-link">
              Roadmap
            </a>
          </li>
          <li className="navbar-link-item">
            <a href="#" className="navbar-link">
              Comic
            </a>
          </li>
          <li className="navbar-link-item">
            <a href="#" className="navbar-link">
              Team
            </a>
          </li>
        </ul>
        <a href="#" className="action_btn">
          Connect Wallet
        </a>
        <div className="toggle_btn">
          <i className={`fa-solid ${isDropdownOpen ? 'fa-bars' : 'fa-times'}`}>
            {isDropdownOpen ?  <FaTimes /> : <FaBars/>}
          </i>
        </div>
      </div>
      <div className={`dropdown_menu ${isDropdownOpen ? 'open' : ''}`}>
        <li className="navbar-link-item">
          <a href="#" className="navbar-link">
            Home
          </a>
        </li>
        <li className="navbar-link-item">
          <a href="#" className="navbar-link">
            Comic
          </a>
        </li>
        <li className="navbar-link-item">
          <a href="#" className="navbar-link">
            Roadmap
          </a>
        </li>
        <li className="navbar-link-item">
          <a href="#" className="navbar-link">
            Content
          </a>
        </li>
        <li>
          <a href="#" className="action_btn">
            Connect Wallet
          </a>
        </li>
      </div>
    </header>
  );
}

export default Navbar;
